<template>
    <div class="clue_info_box">
        <el-form label-position="left" size="small" label-width="90px">
            <div class="left_form">
                <el-form-item label="线索产品">
                    {{ $tableDataHandle.productCategory(clueData.productClue) }}
                </el-form-item>
                <el-form-item label="渠道分类">
                    {{ clueData.sourceCategory == 1 ? '市场' : '销售' }}
                </el-form-item>
                <el-form-item
                    label="投放站点"
                    v-if="clueData.sourceCategory == 1"
                    >{{ clueData.launchAccountName || '- -' }}
                </el-form-item>
                <el-form-item label="线索类型">
                    {{ $tableDataHandle.clueType(clueData.clueType) }}
                </el-form-item>
                <el-form-item label="客户标签">
                    <div class="label_box" v-if="customerTagList.length > 0">
                        <span
                            v-for="item in customerTagList"
                            :key="item.id"
                            :class="item.category == 0 ? 'blue' : 'orange'"
                        >
                            {{ item.tagName }}</span
                        >
                    </div>
                    <div v-else>- -</div>
                </el-form-item>
                <el-form-item
                    label="客户名称"
                    style="position: relative; padding-right: 20px"
                >
                    {{ clueData.companyName || '- -' }}
                    <i class="iconfont icon-ziyuanxhdpi" @click="onHistory"></i>
                </el-form-item>
                <el-form-item label="域名">
                    {{ clueData.companyDomain || '- -' }}
                </el-form-item>
                <el-form-item
                    :label="index == 0 ? '关联域名' : ''"
                    v-for="(item, index) in clueData.lineDomainBOList"
                    :key="index"
                >
                    {{ item.domain || '- -' }}
                </el-form-item>
                <el-form-item
                    label="用户填报开通数"
                    v-if="clueData.entryType == 0"
                >
                    {{ clueData.number || '- -' }}
                </el-form-item>
                <el-form-item label="预计用户数">
                    {{
                        clueData.estimateNumber
                            ? $MailStatus.echoEstimateNumber(
                                  clueData.estimateNumber
                              ) + '人'
                            : '- -'
                    }}
                </el-form-item>
                <el-form-item label="预计年限">
                    {{
                        clueData.estimateTerm
                            ? $MailStatus.echoEstimateTerm(
                                  clueData.estimateTerm
                              ) + '年'
                            : '- -'
                    }}
                </el-form-item>
                <el-form-item label="原邮箱品牌">
                    {{ $tableDataHandle.primaryEmailBrand(clueData) }}
                </el-form-item>
                <el-form-item label="所在地">
                    {{ $address.address(clueData.provinceId, clueData.cityId) }}
                </el-form-item>
                <el-form-item label="所属行业">
                    {{ $tableDataHandle.industry(clueData) }}
                </el-form-item>
                <el-form-item label="备注">
                    {{ clueData.lineRemark || '- -' }}
                </el-form-item>
            </div>
            <div class="right_form">
                <el-form-item label="需求">
                    {{ clueData.remark || '- -' }}
                </el-form-item>
                <el-form-item label="留资方式">
                    {{
                        $tableDataHandle.retentionMethod(
                            clueData.retentionMethod
                        )
                    }}
                </el-form-item>
                <el-form-item label="客户类型">
                    {{ clueData.companyType == 1 ? '企业' : '个人' }}
                </el-form-item>
                <el-form-item label="客户">
                    {{
                        clueData.customerBOList &&
                        clueData.customerBOList.length > 0
                            ? clueData.customerBOList[0].companyName
                            : '- -' || '- -'
                    }}
                </el-form-item>
                <el-form-item
                    :label="index == 0 ? '联系方式' : ''"
                    v-for="(item, index) in clueData.lineContactBOList"
                    :key="item.id"
                >
                    {{
                        $tableDataHandle.contactWay(item)
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{
                        item.contactContent
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.contactName }}
                </el-form-item>

                <el-form-item label="到期时间">
                    {{ clueData.companyExpireTime || '- -' }}
                </el-form-item>
                <el-form-item label="地址">
                    {{ clueData.address || '- -' }}
                </el-form-item>
                <el-form-item label="联系备注">
                    {{ clueData.contactRemark || '- -' }}
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<script>
import { relationList } from '@/api/user/label.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            clueData: {},
            customerTagList: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.clueData = row;

            this.relationList(row.id);
        },
        onHistory() {
            this.$emit('onHistory');
        },
        // 获取所属标签
        relationList(id) {
            let data = {
                param: {
                    bandingTableType: 1,
                    bandingId: id,
                },
            };
            relationList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerTagList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.clue_info_box {
    height: 100%;
    overflow-y: auto;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        position: absolute;
        font-weight: normal;
        left: 250px;
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .el-form {
        display: flex;
        justify-content: space-between;
        .el-form-item {
            margin-bottom: 6px !important;
        }
        /deep/ .el-form-item__label {
            color: #666 !important;
            font-weight: normal !important;
        }
        /deep/ .el-form-item__content {
            color: #333 !important;
            font-weight: bold !important;
        }
    }
    .left_form {
        width: 49%;
        padding-left: 35px;
        box-sizing: border-box;
        padding-top: 5px;
        padding-right: 10px;
    }
    .right_form {
        width: 49%;
        box-sizing: border-box;
        padding-left: 35px;
        padding-top: 5px;
        padding-right: 10px;
    }
    .label_box {
        span {
            display: inline-block;
            padding: 5px 10px;
            font-size: 14px;
            border: 1px solid #999;
            border-radius: 2px;
            line-height: 1;
            font-weight: normal !important;
        }
        span + span {
            margin-left: 10px;
        }
        .blue {
            border-color: #2370eb;
            color: #2370eb;
        }
        .orange {
            border-color: #fa8c15;
            color: #fa8c15;
        }
    }
}
</style>
