<template>
    <div class="history" v-if="data.length > 0">
        <div v-for="item in data" class="list" :key="item.id">
            <span>{{ item.createTime || '- -' }}</span>
            <p>{{ handle(item) || '- -' }}</p>
            <!-- 123 -->
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            data: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
        },
        handle(item) {
            let str = item.content;
            let text = str
                .replace(
                    '%s',
                    item.firstAdminName ? item.firstAdminName : '',
                    1
                )
                .replace(
                    '%s',
                    item.secondAdminName ? item.secondAdminName : '',
                    2
                );
            return text;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.history {
    padding: 10px 10px 20px 20px;
    .list {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        position: relative;
        span {
            color: #666;
        }
        p {
            margin-left: 15px;
            display: inline-block;
            flex: 1;
            font-weight: bold;
            word-break: break-all;
        }
    }
    .list:before {
        content: '';
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background: #2370eb;
        border-radius: 50%;
    }
    .list + .list {
        margin-top: 14px;
    }
}
</style>
