<template>
    <div class="detail_box">
        <div
            class="top_title"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="title_box">
                <i class="iconfont icon-huikuan"></i>
                线索详情
                <span
                    :class="
                        clue.status == 4
                            ? 'bg wxiao'
                            : clue.status == 5
                            ? 'bg gjz'
                            : clue.status == 8
                            ? 'bg yjc'
                            : clue.status == 12
                            ? 'bg dfp'
                            : clue.status == 13
                            ? 'bg ych'
                            : clue.status == 14
                            ? 'bg yfp'
                            : clue.status == 15
                            ? 'bg ycj'
                            : 'bg'
                    "
                    >{{ $tableDataHandle.clueStatus(clue.status) }}</span
                >
                <el-button @click="onClose">
                    <i class="el-icon-arrow-left"></i> 返回列表
                </el-button>
            </div>
            <div style="color: #666666">
                <span class="cicle"><i>线索</i></span>
                <p>分配部门：{{ clue.followDepartmentName || '- -' }}</p>
                <p style="margin-left: 48px">
                    负责人：{{ clue.followAdminName || '- -' }}
                </p>
                <!-- <p style="margin-left: 48px">
                    指导人：{{ personConfig.receiveAdminName || '- -' }}
                </p> -->
                <p style="margin-left: 48px">
                    创建人：{{ clue.adminName || '- -' }}
                </p>
                <p
                    style="margin-left: 48px; flex: 1"
                    v-if="
                        clue.lineSynergyList && clue.lineSynergyList.length > 0
                    "
                >
                    协作人：
                    <span v-for="item in clue.lineSynergyList" :key="item.id">
                        {{ item.synergyAdminName }}
                    </span>
                </p>
                <p style="margin-left: 48px; flex: 1" v-else>协作人：- -</p>
            </div>
        </div>
        <div class="info">
            <div class="left_box">
                <div class="left_title_box">线索信息</div>
                <ClueInfo ref="clueInfo" @onHistory="onHistory"></ClueInfo>
            </div>
            <div class="right_box">
                <div>
                    <div class="left_title_box">
                        客户：{{
                            clue.customerBOList &&
                            clue.customerBOList.length > 0
                                ? clue.customerBOList[0].companyName || '- -'
                                : '- -'
                        }}
                        <el-button
                            v-show="
                                cusomerFold &&
                                clue.customerBOList &&
                                clue.customerBOList.length > 0
                            "
                            @click="cusomerFold = !cusomerFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="
                                !cusomerFold &&
                                clue.customerBOList &&
                                clue.customerBOList.length > 0
                            "
                            @click="cusomerFold = !cusomerFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </div>
                    <el-collapse-transition>
                        <Customer
                            @changeCusomerFold="
                                (boolean) => {
                                    this.cusomerFold = boolean;
                                }
                            "
                            v-show="
                                cusomerFold &&
                                clue.customerBOList &&
                                clue.customerBOList.length > 0
                            "
                            ref="customer"
                        ></Customer>
                    </el-collapse-transition>
                </div>
                <!-- <div class="interval"></div> -->
                <div>
                    <div class="left_title_box">
                        往来记录

                        <el-button
                            v-show="dealingFold"
                            @click="dealingFold = !dealingFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!dealingFold"
                            @click="dealingFold = !dealingFold"
                            type="text"
                            style="color: #2162cb; float: right; margin-left: 0"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                        <el-button
                            v-if="btnP.dealings"
                            @click="onAddDealings"
                            type="primary"
                            size="mini"
                            style="
                                float: right;
                                margin-right: 12px;
                                color: #2370eb;
                                background: #fff;
                                border-color: #2370eb;
                                margin-top: 16px;
                            "
                        >
                            新增往来
                        </el-button>
                    </div>
                    <el-collapse-transition>
                        <DealingHistory
                            v-show="dealingFold"
                            ref="dealingHistory"
                            @clueDealingList="clueDealingList"
                        ></DealingHistory>
                    </el-collapse-transition>
                </div>
                <div class="interval"></div>
                <div>
                    <div class="left_title_box">
                        线索历史
                        <el-button
                            v-show="historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="color: #2162cb; float: right"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </div>
                    <el-collapse-transition>
                        <ClueHistory
                            v-show="historyFold"
                            ref="clueHistory"
                        ></ClueHistory>
                    </el-collapse-transition>
                </div>
            </div>
        </div>
        <!-- 信息编辑历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    信息编辑历史
                </div>
            </template>
            <InfoHistory @close="handleClose" ref="infoHistory" />
        </el-drawer>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="dialogDealing"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加往来
                </div>
            </template>
            <div>
                <AddDealing ref="adddealing" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getClue,
    clueHistory,
    clueDealingList,
    personConfigGet,
} from '@/api/clue/depClue.js';
import { configList } from '@/api/configure/configure.js';
import ClueInfo from './components/clueInfo.vue';
import DealingHistory from './components/dealing.vue';
import ClueHistory from './components/history.vue';
import Customer from './components/customer.vue';
import InfoHistory from './components/infoHistory.vue';
import AddDealing from './components/addDealing.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            cusomerFold: false,
            dealingFold: true,
            historyFold: true,

            direction: 'rtl',
            drawerHistory: false,
            dialogDealing: false,
            clue: {},
            // personConfig: {
            //     receiveAdminName: '',
            // },
            adminId: sessionStorage.getItem('adminId'),
            btnP: {},
            departmentId: sessionStorage.getItem('departmentId'),
        };
    },
    components: {
        ClueInfo,
        DealingHistory,
        ClueHistory,
        Customer,
        InfoHistory,
        AddDealing,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = JSON.parse(JSON.stringify(btn));
            this.getClue();
            this.clueHistory();

            this.clueDealingList();
        },
        onAddDealings() {
            this.dialogDealing = true;

            setTimeout(() => {
                this.$refs.adddealing.getData(this.$route.query.id);
            }, 0);
        },
        //线索详情
        getClue() {
            let data = {
                param: {
                    id: this.$route.query.id,
                    departmentId: this.$route.query.departmentId,
                },
            };
            getClue(data).then((res) => {
                if (res.code == 200) {
                    this.clue = res.data;
                    // if (res.data.followAdminId) {
                    //     console.log(123);
                    //     this.configList();
                    // }
                    this.$refs.clueInfo.getData(res.data);
                    this.$refs.customer.getData(
                        res.data.customerBOList || [{}]
                    );
                }
            });
        },
        // 线索历史
        clueHistory() {
            let data = {
                param: {
                    lineId: this.$route.query.id,
                },
            };
            clueHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.clueHistory.getData(res.data.list);
                }
            });
        },
        // 线索往来
        clueDealingList() {
            let data = {
                param: {
                    lineId: this.$route.query.id,
                },
            };
            clueDealingList(data).then((res) => {
                console.log(res);
                if (res.code == 200) {
                    this.$refs.dealingHistory.getData(res.data.list);
                }
            });
        },

        onHistory() {
            let data = {
                param: {
                    lineId: this.$route.query.id,
                    type: 2,
                },
                pageSize: 0,
                pageNum: 0,
            };
            clueHistory(data).then((res) => {
                if (res.code == 200) {
                    if (res.data.list && res.data.list.length > 0) {
                        this.drawerHistory = true;
                        setTimeout(() => {
                            this.$refs.infoHistory.getData(res.data.list);
                        }, 0);
                    } else {
                        return this.$message.error('无线索编辑历史');
                    }
                }
            });
        },
        onClose() {
            this.$router.go(-1);
        },
        handleClose() {
            this.drawerHistory = false;
            this.dialogDealing = false;
            this.clueDealingList();
        },
        // getSynergy(synergy) {
        //     let str = '';

        //     synergy && synergy.length > 0
        //         ? synergy.forEach((item, index) => {
        //               if (index == synergy.length - 1) {
        //                   str += item.name;
        //               } else {
        //                   str += item.name + '、';
        //               }
        //           })
        //         : (str = '- -');
        //     return str;
        // },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .top_title {
        background-color: #fff;
        margin-bottom: 12px;
        font-size: 16px;
        padding: 18px 15px 18px 35px;
        width: 100%;
        box-sizing: border-box;
        .title_box {
            display: block;
            line-height: 1.2;
            margin-bottom: 25px;
            font-size: 16px;
            color: #333;
            > i {
                color: #2370eb;
                font-size: 18px;
            }
            button {
                background: rgb(255, 255, 255);
                color: rgb(35, 112, 235);
                border-color: rgb(35, 112, 235);
                padding: 5px 10px;
                float: right;
            }
        }
    }
    .top_title .center_info {
        flex: 1;
        margin-left: 10px;
    }
    .center_info p:first-child {
        margin-bottom: 8px;
    }
    .top_title .cicle {
        color: #fff;
        width: 20px;
        height: 20px;
        background: #2370eb;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    .top_title .cicle i {
        font-size: 16px;
        display: inline-block;
        font-style: normal;
        transform: scale(0.5, 0.5);
        white-space: nowrap;
        line-height: 1.2;
    }
    .top_title div:last-child {
        font-size: 14px;
        width: 100%;
        display: flex;
        span {
            cursor: pointer;
        }
    }
}
.info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background-color: #f0f2f4;
    height: calc(100% - 115px - 51px);
    .left_box {
        flex: 2;
        margin-right: 10px;
    }
    > div {
        background-color: #fff;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .left_title_box {
            height: 60px;
            border-bottom: 1px solid #e5e5e5;
            line-height: 60px;
            position: relative;
            font-size: 14px;
            font-weight: bold;
            padding-left: 15px;
            button {
                margin-top: 10px;
            }
        }
        .left_title_box:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 2px;
            background: #2370eb;
        }
    }
    .right_box {
        flex: 1;
        overflow-y: auto;
        .interval {
            height: 6px;
            background-color: #f5f7fe;
        }
        //    background-color: #f5f7fe;
        //    > div + div {
        //        margin-top: 6px;
        //    }
        //    > div {
        //        background-color: #fff;
        //    }
        .record {
            padding-left: 30px;
            position: relative;
        }
        .record:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 2px;
            background: #2370eb;
        }
        .history_list {
            .item:first-child {
                border-top: 1px solid #eee;
            }
        }
    }
}
.bg {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 15px;
    margin-left: 10px;
}
.gjz {
    background-color: #d7ecff;
    color: #3b99f5;
}
.dfp {
    background-color: #fff1d9;
    color: #ff962b;
}
.yfp {
    background-color: #e9f2ff;
    color: #2370eb;
}
.wxiao {
    background-color: #ffecf0;
    color: #da5120;
}
.yfq {
    background-color: #fff1f1;
    color: #d0021b;
}
.ycj {
    background-color: #dee2ff;
    color: #001df7;
}
.ych {
    background-color: #ffeada;
    color: #d7621c;
}
</style>
